























































/* tslint:disable:no-console */
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import AppBlockNormal from "@/components/block/App-block-normal.vue";
import Utils from "../../utils";
import mixins from "@/mixin/index.ts";
// @Component 修饰符注明了此类为一个 Vue 组件
@Component({
  name: "Marticle",
  components: { AppBlockNormal },
  mixins: [mixins]
})
export default class Marticle extends Vue {
  @Prop() private detail!: any;
  private sourceType: number = 1; // 0游戏资讯 1第三方资讯
  private content: any = "";
  private imgMap: any = "";

  get getAppInfo (): any {
    const self = this
    const appInfo = self.detail.appInfo || {}
    appInfo.block_name = self.$route.query.source_block_name
    appInfo.block_id = self.$route.query.source_block_id
    appInfo.block_type = self.$route.query.source_block_type
    appInfo.rank_id = self.$route.query.source_rank_id
    appInfo.type = 'game'

    appInfo.data_source = self.detail.data_source
    return appInfo
  }
  get getDetail(): any {
    const self: any = this;
    const detail: any = this.detail || {}
    self.content = '';
    // 1第三方资讯 5官方动态都是直接插入结构的
    if (detail && (Number(detail.source_type) === 0 || Number(detail.source_type) === 5)) {
      let content: string = "";
      try {
        self.sourceType = 0
        content = JSON.parse(detail.content);
      } catch (err) {
        self.sourceType = 5;
        // 强制设置为html插入
        content = detail.content;
      }
      self.content = content;
    }
    // 如果source_type == 1,是第三方资讯
    if (detail && Number(detail.source_type) === 1) {
      self.sourceType = 1;
      // 获取实际文章内容的url
      const contentUrl = self.utils.getUrlParam(
        "info",
        decodeURIComponent(detail.content_link)
      );
      self.$doMotion(self, "LOADING_SWITCH", true);
      this.$store
        .dispatch("fetchNewsDetailOther", {
          context: this,
          suffix: contentUrl,
          params: {}
        })
        .then((result: any) => {
          if (
            result.status === 200
            // && result.headers["content-type"] === "application/octet-stream"
            ) {
            const data = result.data;
            if (data) {
              self.$doMotion(self, "LOADING_SWITCH", false);
              return data;
            }
          }
        })
        .then((data: any) => {
          const dt: any = typeof data === "string" ? JSON.parse(data) : data;
          self.content = dt.content;
          self.imgMap = dt.articleMediaMap;
          self.$nextTick(() => {
            self.setImage();
          });
        })
        .catch(e => {
          self.$doMotion(self, "LOADING_SWITCH", false);
          self.$toast.show('网络错误，请刷新重试')
        });
    }
    return detail
  }
  /* @Watch("detail")
  private onChangeDetail(val: any, old: any) {
    const self: any = this;
    if (val && Number(val.source_type) === 0) {
      let content: string = "";
      try {
        content = JSON.parse(val.content);
      } catch (err) {
        content = val.content;
        val.source_type = 2; // 强制设置为html插入
      }
      self.content = content;
    }
    // 如果source_type == 1,是第三方资讯
    if (val && Number(val.source_type) === 1) {
      // 获取实际文章内容的url
      const contentUrl = self.utils.getUrlParam(
        "info",
        decodeURIComponent(val.content_link)
      );
      self.$doMotion(self, "LOADING_SWITCH", true);
      this.$store
        .dispatch("fetchNewsDetailOther", {
          context: this,
          suffix: contentUrl,
          params: {}
        })
        .then((result: any) => {
          if (
            result.status === 200 &&
            result.headers["content-type"] === "application/octet-stream"
          ) {
            const data = result.data;
            if (data) {
              self.$doMotion(self, "LOADING_SWITCH", false);
              return data;
            }
          }
        })
        .then((data: any) => {
          const dt: any = typeof data === "string" ? JSON.parse(data) : data;
          self.content = dt.content;
          self.imgMap = dt.articleMediaMap;
          self.$nextTick(() => {
            self.setImage();
          });
        })
        .catch(e => {
          console.log(e);
          self.$doMotion(self, "LOADING_SWITCH", false);
          self.$toast.show('网络错误，请刷新重试')
        });
    }
  } */
  private setImage() {
    const self: any = this;
    if (!self.imgMap) {
      return;
    }
    const imgs: any = document.querySelectorAll("img.reader_img");
    Array.from(imgs).forEach((img: any, index: number) => {
      const offsetTop = img.offsetTop;
      const scrollHeight = document.documentElement.scrollHeight;
      const differ = scrollHeight - offsetTop;
      const imgUrl = self.imgMap[imgs[index].id].url;
      if (img.getAttribute("src") !== imgUrl) {
        img.setAttribute("src", imgUrl);
        img.onload = () => {
          img.style.height = "auto";
        };
      }
    });
  }
}
