







/* tslint:disable:no-console */
import { Component, Vue } from "vue-property-decorator";

import NavBar from "@/components/Nav-bar.vue";
import Article from "@/components/widget/article.vue";
import mixins from "@/mixin";
import routerMixin from "@/mixin/router-mixin.ts";
import updateTdk from "@/utils/tdk";
import { getHomePageData } from "@/api";
// @Component 修饰符注明了此类为一个 Vue 组件
@Component({
  name: "NewsDetail",
  components: { NavBar, Article },
  mixins: [mixins, routerMixin]
})
export default class NewsDetails extends Vue {
  // 初始数据可以直接声明为实例的属性
  private navs: any[] = [{ name: "资讯", link: "" }];
  private haveServer: boolean = false;
  private detail: any = {};
  private upTime: number = 0;
  private tdk(self: any) {
    return {
      title: `-${self.getDetail.newsTitle}`
    };
  }
  private serverPrefetch() {
    return this.fetchData();
  }
  get getDetail(): any {
    return this.$store.state.pageDatas.newsDetailData || {};
  }
  private fetchData(): any {
    const self: any = this;
    // this.$store.commit('setNewsDetailData', {}) // 清空之前的值，防止不刷新的情况

    const newsId: number = Number(self.$route.query.contentId) || 0;
    self.$doMotion(self, "LOADING_SWITCH", true);
    // 获取首页数据
    return this.$store
      .dispatch("fetchNewsDetail", { context: this, newsId, params: {} })
      .then((result: any) => {
        const data = result.value;
        if (data) {
          data.data_source = "newsDetail";
          data.newsTitle = data.title;
          self.$store.commit('setNewsDetailData', data)
          /* if (self.isServer) {
            self.$forceUpdate()
          } */
        }
      })
      .catch((err: any) => {
        console.log(err);
        self.$toast.show(err.message || '服务器错误，请刷新！');
      })
      .finally(() => {
        self.$doMotion(self, "LOADING_SWITCH", false);
        updateTdk(self);
      });
  }
  private beforeMount(): void {
    // this.$store.replaceState((window as any).__INITIAL_STATE__);
  }
  private mounted(): void {
    const self: any = this;
    const newsId: number =
      Number(
        self.$route.query.contentId || self.utils.getUrlParam("contentId")
      ) || 0;
    if (!newsId) {
      this.$router.replace({
        name: "index"
      });
      return;
    }
    if (!self.getDetail.data_source) {  // 是否有值
      self.fetchData();
    }
  }
  private destroyed() {
    this.$store.commit('setNewsDetailData', null) // 修改store
    // this.$store.replaceState((window as any).__INITIAL_STATE__);
  }
}
